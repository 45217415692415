<template>
    <div>
        <div class="form-row">
            <div class="form-col">
                <label>show navigation arrows</label>
                <toggle-input v-model="slider.showNavigationArrows" />

                <label class="mt-8">show navigation dots</label>
                <toggle-input v-model="slider.showNavigationDots" />

                <label class="mt-8">show overflow previews</label>
                <toggle-input v-model="slider.showOverflowPreviews" />
            </div>
            <div class="form-col">
                <label>Images</label>
                <file-manager
                    :main-directory="'cms'"
                    :library="getLibraryImageFilesByBaseDirectory(['marketing', 'cms'])"
                    :libraryFilters="['marketing', 'cms']"
                    :max-nb-of-files="10"
                    :max-upload-size="20"
                    :cropper-options="{aspectRatio: 16/9, cropBoxResizable: true, zoomOnWheel: false}"
                    :noCropper="true"
                    @uploaded-to-s3="createWebsiteImage"
                    v-model="slider.images"
                >
                    <template v-slot:hint="{ canAddFiles, maxNbOfFiles, maxUploadSize }">
                        <span class="form-hint" v-if="canAddFiles">
                            Select at least {{ minNbImages }} images (up to {{ maxNbOfFiles }}).
                            The max upload file size is {{ maxUploadSize }}MB.
                        </span>
                    </template>
                </file-manager>
                <span v-if="hasError('slider.images')" class="form-error">{{ showError('slider.images') }}</span>
            </div>
        </div>
        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Widget from "@/mixins/Widget";
    import ToggleInput from "@/components/ui/ToggleInput";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import FileManager from "@/components/ui/filemanager/FileManager";

    export default {
        mixins: [ ModalNavigation, Widget ],
        components: { ModalFooter, FileManager, ToggleInput },
        emits: ['save-page-widget'],
        data: () => {
            return {
                slider: {
                    images: [],
                    showNavigationArrows: false,
                    showNavigationDots: false,
                    showOverflowPreviews: false,
                },
                minNbImages: 5,
                isProcessing: false,
            }
        },
        computed: {
            ...mapGetters({
                getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
                getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId',
                getFileIdByThumbnailUrl: 'files/getFileIdByThumbnailUrl' 
            })
        },
        methods: {
            save() {
                if(this.validator.passes()) {
                    this.isProcessing = true;
                    let images=[]
                    for( let i = 0; i < this.slider.images.length; i++){
                        images[i] = (this.getThumbnailUrlByFileId(this.slider.images[i]));
                    }
                    this.$emit('save-page-widget', {
                        images: JSON.stringify(images),
                        showNavigationArrows: this.slider.showNavigationArrows,
                        showNavigationDots: this.slider.showNavigationDots,
                        showOverflowPreviews: this.slider.showOverflowPreviews,
                    });
                }
            }
        },
        validations: {
            'slider.images' : 'required | min: minNbImages',
        },
        created() {
            this.initValidator();

            this.validator.setCustomErrorMessages({
                'slider.images.min' : 'You must select at least ' + this.minNbImages + ' images.',
            })

            // Load values when editing the widget
            if (this.values.length) {
                let images=[]
                for( let i = 0; i < JSON.parse(this.getValue('images')).length; i++){
                    images[i] = (this.getFileIdByThumbnailUrl(JSON.parse(this.getValue('images'))[i]));
                }
                this.slider.images = JSON.stringify(images) ? images : [];
                this.slider.showNavigationArrows = this.getValue('showNavigationArrows');
                this.slider.showNavigationDots = this.getValue('showNavigationDots');
                this.slider.showOverflowPreviews = this.getValue('showOverflowPreviews');
            }
        }
    }
</script>
